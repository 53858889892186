import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateEvents = () => {
  const { id } = useParams(); // Get the event ID from the URL
  const [event, setEvent] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const imageInputRef = useRef(); // Create a ref for the file input
  const history = useNavigate(); // Hook for navigation after update

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(`https://teachire.com/api/events/${id}`);
        setEvent(response.data);

        // If the response contains a valid date, format it to yyyy-mm-dd
        if (response.data.date) {
          const formattedDate = new Date(response.data.date).toISOString().split('T')[0];
          setEvent((prevState) => ({
            ...prevState,
            date: formattedDate
          }));
        }

        // Construct full URL for the event image
        if (response.data.eventImage) {
          const imageUrl = `https://teachire.com/${response.data.eventImage}`;
          console.log("Image URL:", imageUrl); // Log to verify URL
          setImagePreview(imageUrl);
        }
      } catch (error) {
        console.error('Failed to fetch event data', error);
      }
    };

    fetchEventData();
  }, [id]);

  const handleChange = (e) => {
    if (e.target.name === "eventImage") {
      const file = e.target.files[0];
      setEvent({ ...event, eventImage: file });

      // Create a preview URL for the selected image
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(null);
      }
    } else {
      setEvent({ ...event, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('title', event.title);
    data.append('description', event.description);
    data.append('date', event.date);
    data.append('time', event.time);
    data.append('location', event.location);
    
    // Append the image file if it exists
    if (event.eventImage) {
      data.append('eventImage', event.eventImage);
    }

    try {
      const response = await axios.put(`https://teachire.com/api/events/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Event updated:', response.data);
      toast.success('Event updated successfully!');
      history('/events'); // Redirect after successful update
    } catch (error) {
      console.error('Error updating event:', error);
      toast.error('Error updating event. Please try again.');
    }
  };

  return event ? (
    <div className="container my-4">
      <h2>Edit Event</h2>

      <form onSubmit={handleSubmit} className='border p-5 rounded shadow'>
        {/* Event Title */}
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Event Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={event.title}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Event Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={event.description}
            onChange={handleChange}
            rows="3"
            required
          ></textarea>
        </div>

        {/* Event Date */}
        <div className="mb-3">
          <label htmlFor="date" className="form-label">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={event.date}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Time */}
        <div className="mb-3">
          <label htmlFor="time" className="form-label">Time</label>
          <input
            type="time"
            className="form-control"
            id="time"
            name="time"
            value={event.time}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Location */}
        <div className="mb-3">
          <label htmlFor="location" className="form-label">Location</label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            value={event.location}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Image */}
        <div className="mb-3">
          <label htmlFor="eventImage" className="form-label">Event Image</label>
          <input
            type="file"
            className="form-control"
            id="eventImage"
            name="eventImage"
            onChange={handleChange}
            accept="image/*"
            ref={imageInputRef} // Attach the ref to the input
          />
        </div>

        {/* Image Preview */}
        {imagePreview && (
          <div className="mb-3">
            <img
              src={imagePreview}
              alt="Event Preview"
              className="img-fluid"
              style={{ maxHeight: '200px', objectFit: 'cover' }}
              onError={(e) => { e.target.src = ''; e.target.alt = 'Image not found'; }}
            />
          </div>
        )}

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">Update Event</button>
      </form>

      {/* Toast Container for notifications */}
      <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default UpdateEvents;
