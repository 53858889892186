import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Advocates = () => {
  const [advocates, setAdvocates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAdvocate, setSelectedAdvocate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const advocatesPerPage = 5;

  useEffect(() => {
    const fetchAdvocates = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/advocates');
        setAdvocates(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch advocates data');
        setLoading(false);
      }
    };

    fetchAdvocates();
  }, []);

  const handleDeleteClick = (advocate) => {
    setSelectedAdvocate(advocate);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedAdvocate) return;

    try {
      await axios.delete(`https://teachire.com/api/advocates/delete/${selectedAdvocate._id}`);
      setAdvocates(advocates.filter((adv) => adv._id !== selectedAdvocate._id));
      toast.success('Advocate deleted successfully!');
      setSelectedAdvocate(null);
    } catch (error) {
      console.error('Failed to delete advocate', error);
      toast.error('Failed to delete advocate. Please try again.');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  // Pagination logic
  const indexOfLastAdvocate = currentPage * advocatesPerPage;
  const indexOfFirstAdvocate = indexOfLastAdvocate - advocatesPerPage;
  const currentAdvocates = advocates.slice(indexOfFirstAdvocate, indexOfLastAdvocate);
  const totalPages = Math.ceil(advocates.length / advocatesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  if (loading) {
    return <p>Loading advocates...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Advocates</h2>
      </div>

      <table className="table table-striped table-hover border shadow">
        <thead className="thead-dark">
          <tr>
            <th>S. No</th>
            <th>Advocate Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>Years of Experience</th>
            <th>Bar Registration Number</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentAdvocates.length > 0 ? (
            currentAdvocates.map((advocate, index) => (
              <tr key={advocate._id}>
                <td>{indexOfFirstAdvocate + index + 1}</td>
                <td>
                  {advocate.advocateImage ? (
                    <img
                      src={`https://teachire.com/uploads/advocates/${advocate.advocateImage}`}
                      alt={advocate.name}
                      onError={(e) => { e.target.onerror = null; e.target.src = "path/to/fallback/image.png"; }}
                      style={{ width: '100px', height: 'auto' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{advocate.name}</td>
                <td>{advocate.email}</td>
                <td>{advocate.mobileNumber}</td>
                <td>{advocate.yearsOfExperience}</td>
                <td>{advocate.barRegistrationNumber}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(advocate)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8" className="text-center">No advocates available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Advocate</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this advocate?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advocates;
