import React from 'react';

const Messages = () => {
  return (
    <div>
      <h3>Messages Page</h3>
    </div>
  );
};

export default Messages;
