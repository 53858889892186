import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNews = () => {
  const [newstitle, setNewsTitle] = useState('');
  const [newsContent, setNewsContent] = useState('');
  const [postedDate, setPostedDate] = useState('');
  const [postedTime, setPostedTime] = useState('');
  const [newsImage, setNewsImage] = useState(null); // State for image
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const imageInputRef = useRef(); // Ref for file input
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    if (e.target.name === "newsImage") {
      const file = e.target.files[0];
      setNewsImage(file);

      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(null);
      }
    } else {
      const { id, value } = e.target;
      if (id === 'newstitle') setNewsTitle(value);
      else if (id === 'newsContent') setNewsContent(value);
      else if (id === 'postedDate') setPostedDate(value);
      else if (id === 'postedTime') setPostedTime(value);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('newstitle', newstitle);
    formData.append('newsContent', newsContent);
    formData.append('postedDate', postedDate);
    formData.append('postedTime', postedTime);
    if (newsImage) formData.append('newsImage', newsImage);

    try {
      const response = await axios.post('https://teachire.com/api/news/add', formData, {
      // const response = await axios.post('https://teachire.com/api/news/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('News added successfully:', response.data);
      toast.success('News added successfully!');
      // Reset the form fields and preview
      setNewsTitle('');
      setNewsContent('');
      setPostedDate('');
      setPostedTime('');
      setNewsImage(null);
      setImagePreview(null);
      imageInputRef.current.value = ''; // Clear file input
      navigate('/news-krby');
    } catch (error) {
      console.error('Error adding news:', error);
      toast.error('Failed to add news. Please try again.');
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Add News</h2>
      <form onSubmit={handleSubmit} className="border p-5 rounded shadow">
        <div className="mb-3">
          <label htmlFor="newstitle" className="form-label">News Title</label>
          <input
            type="text"
            className="form-control"
            id="newstitle"
            value={newstitle}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="newsContent" className="form-label">News Content</label>
          <textarea
            className="form-control"
            id="newsContent"
            rows="4"
            value={newsContent}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="postedDate" className="form-label">Posted Date</label>
          <input
            type="date"
            className="form-control"
            id="postedDate"
            value={postedDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="postedTime" className="form-label">Posted Time</label>
          <input
            type="time"
            className="form-control"
            id="postedTime"
            value={postedTime}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="newsImage" className="form-label">News Image</label>
          <input
            type="file"
            className="form-control"
            id="newsImage"
            name="newsImage"
            onChange={handleChange}
            accept="image/*"
            ref={imageInputRef}
          />
        </div>
        {imagePreview && (
          <div className="mb-3">
            <img src={imagePreview} alt="Preview" className="img-fluid" style={{ maxHeight: '200px', objectFit: 'cover' }} />
          </div>
        )}
        <button type="submit" className="btn btn-primary">Add News</button>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddNews;
