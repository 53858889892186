import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Lawyers = () => {
  const [lawyers, setLawyers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLawyer, setSelectedLawyer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const lawyersPerPage = 5;

  useEffect(() => {
    const fetchLawyers = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/lawyers');
        setLawyers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch students data');
        setLoading(false);
      }
    };

    fetchLawyers();
  }, []);

  const handleDeleteClick = (lawyer) => {
    setSelectedLawyer(lawyer);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedLawyer) return;

    try {
      await axios.delete(`https://teachire.com/api/lawyers/delete/${selectedLawyer._id}`);
      setLawyers(lawyers.filter((lawyer) => lawyer._id !== selectedLawyer._id));
      toast.success('Lawyer deleted successfully!');
      setSelectedLawyer(null);
    } catch (error) {
      console.error('Failed to delete lawyer', error);
      toast.error('Failed to delete lawyer. Please try again.');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  const indexOfLastLawyer = currentPage * lawyersPerPage;
  const indexOfFirstLawyer = indexOfLastLawyer - lawyersPerPage;
  const currentLawyers = lawyers.slice(indexOfFirstLawyer, indexOfLastLawyer);
  const totalPages = Math.ceil(lawyers.length / lawyersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  if (loading) {
    return <p>Loading lawyers...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Students</h2>
      </div>

      <table className="table table-striped table-hover border shadow">
        <thead className="thead-dark">
          <tr>
            <th>S. No</th>
            <th>Student Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>Address</th>
            <th>Birth Date</th>
            <th>Gender</th>
            <th>Current School</th>
            <th>Year of Study</th>
            <th>Expected Graduation Year</th>
            <th>Achievements</th>
            <th>Skills of Interest</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentLawyers.length > 0 ? (
            currentLawyers.map((lawyer, index) => (
              <tr key={lawyer._id}>
                <td>{indexOfFirstLawyer + index + 1}</td>
                <td>
                  {lawyer.lawyerImage ? (
                    <img
                      src={`https://teachire.com/uploads/lawyers/${lawyer.lawyerImage}`}
                      alt={lawyer.name}
                      onError={(e) => { e.target.onerror = null; e.target.src = "path/to/fallback/image.png"; }}
                      style={{ width: '100px', height: 'auto' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{lawyer.name}</td>
                <td>{lawyer.email}</td>
                <td>{lawyer.mobileNumber}</td>
                <td>{lawyer.address}</td>
                <td>{lawyer.birthDate}</td>
                <td>{lawyer.gender}</td>
                <td>{lawyer.currentSchool}</td>
                <td>{lawyer.yearofStudy}</td>
                <td>{lawyer.expectedGraduationYear}</td>
                <td>{lawyer.achievements}</td>
                <td>{lawyer.skillsofInterest}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(lawyer)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">No students available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Student</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this student?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lawyers;
