import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 5;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/events');
        setEvents(response.data);
      } catch (error) {
        console.error('Failed to fetch events', error);
      }
    };

    fetchEvents();
  }, []);

  const currentDate = new Date().toISOString().split("T")[0];
  const upcomingEvents = events.filter(event => event.date >= currentDate);
  const pastEvents = events.filter(event => event.date < currentDate);
  const eventsToRender = activeTab === 'upcoming' ? upcomingEvents : pastEvents;

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = eventsToRender.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil(eventsToRender.length / eventsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1); // Reset to the first page when tab changes
  };

  const handleDelete = async () => {
    if (!eventToDelete) return;

    try {
      await axios.delete(`https://teachire.com/api/events/delete/${eventToDelete}`);
      setEvents(events.filter(event => event._id !== eventToDelete));
      setIsModalOpen(false);
      setEventToDelete(null);
      toast.success('Event deleted successfully!');
    } catch (error) {
      console.error('Failed to delete event', error);
      toast.error('Failed to delete event');
    }
  };

  const handleEdit = (eventId) => {
    // Navigate to the edit page where you can handle editing the event
    navigate(`/updateevents/${eventId}`);
  };

  const renderEventsTable = (eventsToRender) => (
    <table className="table table-bordered table-striped table-hover shadow">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Description</th>
          <th scope="col">Date</th>
          <th scope="col">Time</th>
          <th scope="col">Location</th>
          <th scope="col">Image</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {eventsToRender.length > 0 ? (
          eventsToRender.map((event, index) => (
            <tr key={index}>
              <td>{event.title}</td>
              <td>{event.description}</td>
              <td>{event.date}</td>
              <td>{event.time}</td>
              <td>{event.location}</td>
              <td>
                {event.eventImage ? (
                  <img 
                    src={`https://teachire.com/uploads/events/${event.eventImage}`} 
                    alt={event.title} 
                    style={{ width: '100px', height: 'auto' }}
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td>
                {/* Delete Button */}
                <button 
                  className="btn btn-danger" 
                  onClick={() => {
                    setEventToDelete(event._id);
                    setIsModalOpen(true);
                  }}
                >
                  Delete
                </button>
                {/* Edit Button */}
                <button 
                  className="btn btn-primary ml-2" 
                  onClick={() => handleEdit(event._id)} // Navigate to the edit page on click
                >
                  Edit
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7" className="text-center">No events available</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Event</h5>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this event?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={onConfirm}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container my-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Events</h2>
        <button className="btn btn-primary" onClick={() => navigate('/addevents')}>Add Event</button>
      </div>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'upcoming' ? 'active' : ''}`}
            onClick={() => handleTabChange('upcoming')}
          >
            Upcoming Events
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'past' ? 'active' : ''}`}
            onClick={() => handleTabChange('past')}
          >
            Past Events
          </button>
        </li>
      </ul>

      {renderEventsTable(currentEvents)}

      {/* Pagination Controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={handlePrevious}>Previous</button>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
            </li>
          ))}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={handleNext}>Next</button>
          </li>
        </ul>
      </nav>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleDelete} />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnHover />
    </div>
  );
};

export default Events;
