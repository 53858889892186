import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Sidebar = () => {
  return (
    <div className="d-flex flex-column bg-light p-3" style={{ width: '250px', height: '100vh' }}>
      <h2>Menu</h2>
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/users">Users</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/businesses">Businesses</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/events">Events</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/news-krby">News-KRBY</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/news-skdns">News-SKDNS</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/jobs">Jobs</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/matrimony">Matrimony</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/advocates">Advocates</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/students">Students</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/scholarships">Scholarships</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/doctors">Doctors</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/sangas">Sangas</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/jobseekers">Job Seekers</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/employees">Govt Employees</Link>
        </li>
        <li>
          <Link className="nav-link" to="/messages">Messages</Link>
        </li>
        <li>
          <Link className="nav-link" to="/settings">Settings</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
