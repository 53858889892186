import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const News = () => {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);

  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const newsPerPage = 5; // Adjust the number of news per page

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/news');
        setNewsData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch news data');
        setLoading(false);
        toast.error('Failed to fetch news data');
      }
    };

    fetchNews();
  }, []);

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = newsData.slice(indexOfFirstNews, indexOfLastNews);
  const totalPages = Math.ceil(newsData.length / newsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };
  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  const handleDelete = async () => {
    if (!newsToDelete) return;

    try {
      await axios.delete(`https://teachire.com/api/news/delete/${newsToDelete}`);
      setNewsData(newsData.filter(news => news._id !== newsToDelete));
      setIsModalOpen(false);
      setNewsToDelete(null);
      toast.success('News article deleted successfully!');
    } catch (err) {
      setError('Failed to delete news article');
      toast.error('Failed to delete news article');
    }
  };

  if (loading) {
    return <p>Loading news...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete News</h5>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this news article?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>News KRBY</h2>
        <button className="btn btn-primary" onClick={() => navigate('/addnews')}>Add News</button>
      </div>

      <table className="table table-striped table-hover border shadow rounded">
        <thead className="thead-dark">
          <tr>
            <th scope="col">S.No</th> {/* New S.No column */}
            <th scope="col">Title</th>
            <th scope="col">Content</th>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Image</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentNews.map((news, index) => (
            <tr key={index}>
              <td>{indexOfFirstNews + index + 1}</td> {/* Serial number */}
              <td>{news.newstitle}</td>
              <td>{news.newsContent}</td>
              <td>{news.postedDate}</td>
              <td>{news.postedTime}</td>
              <td>
                {news.newsImage ? (
                  <img 
                    src={`https://teachire.com/uploads/news/${news.newsImage}`} 
                    alt={news.newstitle} 
                    style={{ width: '100px', height: 'auto' }} 
                    onError={(e) => { e.target.onerror = null; e.target.src="path/to/fallback/image.png"; }}
                  />
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td>
                <button 
                  className="btn btn-danger" 
                  onClick={() => {
                    setNewsToDelete(news._id);
                    setIsModalOpen(true);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onConfirm={handleDelete} 
      />

      <ToastContainer />
    </div>
  );
};

export default News;
