import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Users from './pages/Users';
import Messages from './pages/Messages';
import Settings from './pages/Settings';
import Business from './pages/Business';
import Events from './pages/events/Events';
import AddEvents from './pages/events/AddEvents';
import News from './pages/news/News';
import AddNews from './pages/news/AddNews';
import Jobs from './pages/jobs/Jobs';
import AddJobs from './pages/jobs/AddJobs';
import MatrimonyList from './pages/matrimony/MatrimonyList';
import NewsSKDNS from './pages/newsSKDNS/NewsSKDNS';
import AddNewsSKDNS from './pages/newsSKDNS/AddNewsSKDNS';
import Advocates from './pages/advocate/Advocates';
import Lawyers from './pages/Lawyers/Lawyers';
import Scholarships from './pages/scholarships/Scholarships';
import AddScholarships from './pages/scholarships/AddScholarships';
import Doctors from './pages/doctors/Doctors';
import Sangas from './pages/sangas/Sangas';
import Jobseekers from './pages/jobseekers/Jobseekers';
import UpdateEvents from './pages/events/UpdateEvents';
import Employees from './pages/employees/Employees';

const AppRouter = () => {
  return (
    <Router>
      <Navbar />
      <div className="d-flex">
        <Sidebar />
        <div className="p-4" style={{ flex: 1 }}>
          <Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/businesses" element={<Business />} />
            <Route path="/events" element={<Events />} />
            <Route path="/addevents" element={<AddEvents />} />
            <Route path="/updateevents/:id" element={<UpdateEvents />} />
            <Route path="/news-krby" element={<News />} />
            <Route path="/news-skdns" element={<NewsSKDNS />} />
            <Route path="/addnews" element={<AddNews />} />
            <Route path="/addnews-skdns" element={<AddNewsSKDNS />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/addjobs" element={<AddJobs />} />
            <Route path="/matrimony" element={<MatrimonyList />} />
            <Route path="/advocates" element={<Advocates />} />
            <Route path="/students" element={<Lawyers />} />
            <Route path="/scholarships" element={<Scholarships />} />
            <Route path="/addscholarships" element={<AddScholarships />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/sangas" element={<Sangas />} />
            <Route path="/jobseekers" element={<Jobseekers />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default AppRouter;
