import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddJobs = () => {
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [jobType, setJobType] = useState('');
  const [salary, setSalary] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [postedDate, setPostedDate] = useState('');
  const [skills, setSkills] = useState('');
  const [experience, setExperience] = useState('');
  const [qualification, setQualification] = useState('');
  const [jobCategory, setJobCategory] = useState('');
  const [jobImage, setJobImage] = useState(null); // State for job image
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const imageInputRef = useRef(); // Ref for file input
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { id, value, type, files } = e.target;

    if (type === 'file') {
      const file = files[0];
      setJobImage(file);

      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(null);
      }
    } else {
      switch (id) {
        case 'companyName':
          setCompanyName(value);
          break;
        case 'jobTitle':
          setJobTitle(value);
          break;
        case 'location':
          setLocation(value);
          break;
        case 'jobType':
          setJobType(value);
          break;
        case 'salary':
          setSalary(value);
          break;
        case 'jobDescription':
          setJobDescription(value);
          break;
        case 'postedDate':
          setPostedDate(value);
          break;
        case 'skills':
          setSkills(value);
          break;
        case 'experience':
          setExperience(value);
          break;
        case 'qualification':
          setQualification(value);
          break;
        case 'jobCategory':
          setJobCategory(value);
          break;
        default:
          break;
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!companyName || !jobTitle || !location || !jobType || !salary || !jobDescription || !postedDate || !skills || !experience || !qualification || !jobCategory) {
      toast.warn('Please fill in all required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('companyName', companyName);
    formData.append('jobTitle', jobTitle);
    formData.append('location', location);
    formData.append('jobType', jobType);
    formData.append('salary', salary);
    formData.append('jobDescription', jobDescription);
    formData.append('postedDate', postedDate);
    formData.append('skills', skills);
    formData.append('experience', experience);
    formData.append('qualification', qualification);
    formData.append('jobCategory', jobCategory);
    if (jobImage) formData.append('jobImage', jobImage); // Add job image to form data

    try {
      const response = await axios.post('https://teachire.com/api/jobs/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Job added successfully:', response.data);
      toast.success('Job added successfully!');
      // Reset form fields and preview
      resetForm();
      navigate('/jobs');
    } catch (error) {
      console.error('Error adding job:', error);
      toast.error('Failed to add job. Please try again.');
    }
  };

  // Function to reset form fields
  const resetForm = () => {
    setCompanyName('');
    setJobTitle('');
    setLocation('');
    setJobType('');
    setSalary('');
    setJobDescription('');
    setPostedDate('');
    setSkills('');
    setExperience('');
    setQualification('');
    setJobCategory('');
    setJobImage(null);
    setImagePreview(null);
    imageInputRef.current.value = ''; // Clear file input
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Add Job</h2>
      <form onSubmit={handleSubmit} className='border p-5 rounded shadow'>
        {/* Form fields for job details */}
        <div className="mb-3">
          <label htmlFor="companyName" className="form-label">Company Name</label>
          <input
            type="text"
            className="form-control"
            id="companyName"
            value={companyName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobTitle" className="form-label">Job Title</label>
          <input
            type="text"
            className="form-control"
            id="jobTitle"
            value={jobTitle}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="location" className="form-label">Location</label>
          <input
            type="text"
            className="form-control"
            id="location"
            value={location}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobType" className="form-label">Job Type</label>
          <input
            type="text"
            className="form-control"
            id="jobType"
            value={jobType}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="salary" className="form-label">Salary</label>
          <input
            type="text"
            className="form-control"
            id="salary"
            value={salary}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobDescription" className="form-label">Job Description</label>
          <textarea
            className="form-control"
            id="jobDescription"
            rows="4"
            value={jobDescription}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="postedDate" className="form-label">Posted Date</label>
          <input
            type="date"
            className="form-control"
            id="postedDate"
            value={postedDate}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="skills" className="form-label">Skills</label>
          <input
            type="text"
            className="form-control"
            id="skills"
            value={skills}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="experience" className="form-label">Experience</label>
          <input
            type="text"
            className="form-control"
            id="experience"
            value={experience}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="qualification" className="form-label">Qualification</label>
          <input
            type="text"
            className="form-control"
            id="qualification"
            value={qualification}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobCategory" className="form-label">Job Category</label>
          <input
            type="text"
            className="form-control"
            id="jobCategory"
            value={jobCategory}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jobImage" className="form-label">Job Image</label>
          <input
            type="file"
            className="form-control"
            id="jobImage"
            onChange={handleChange}
            accept="image/*"
            ref={imageInputRef}
          />
        </div>
        {imagePreview && (
          <div className="mb-3">
            <img src={imagePreview} alt="Preview" className="img-fluid" style={{ maxHeight: '200px' }} />
          </div>
        )}
        <button type="submit" className="btn btn-primary">Add Job</button>
      </form>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default AddJobs;
