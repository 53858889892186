import React, { useState, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEvents = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    location: '',
    eventImage: null
  });

  const [imagePreview, setImagePreview] = useState(null);
  const imageInputRef = useRef(); // Create a ref for the file input

  // Handle input change
  const handleChange = (e) => {
    if (e.target.name === "eventImage") {
      const file = e.target.files[0];
      setFormData({ ...formData, eventImage: file });

      // Create a preview URL for the selected image
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(null);
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('title', formData.title);
    data.append('description', formData.description);
    data.append('date', formData.date);
    data.append('time', formData.time);
    data.append('location', formData.location);
    
    // Append the image file if it exists
    if (formData.eventImage) {
      data.append('eventImage', formData.eventImage);
    }

    try {
      const response = await axios.post('https://teachire.com/api/events/add', data, {
      // const response = await axios.post('https://teachire.com/api/events/add', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Event added:', response.data);
      toast.success('Event added successfully!');

      // Reset form data and image preview
      setFormData({
        title: '',
        description: '',
        date: '',
        time: '',
        location: '',
        eventImage: null // Reset image field
      });
      setImagePreview(null); // Clear image preview
      imageInputRef.current.value = ''; // Clear the file input field
    } catch (error) {
      console.error('Error adding event:', error);
      toast.error('Error adding event. Please try again.');
    }
  };

  return (
    <div className="container my-4">
      <h2>Add New Event</h2>

      <form onSubmit={handleSubmit} className='border p-5 rounded shadow'>
        {/* Event Title */}
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Event Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Event Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="3"
            required
          ></textarea>
        </div>

        {/* Event Date */}
        <div className="mb-3">
          <label htmlFor="date" className="form-label">Date</label>
          <input
            type="date"
            className="form-control"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Time */}
        <div className="mb-3">
          <label htmlFor="time" className="form-label">Time</label>
          <input
            type="time"
            className="form-control"
            id="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Location */}
        <div className="mb-3">
          <label htmlFor="location" className="form-label">Location</label>
          <input
            type="text"
            className="form-control"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </div>

        {/* Event Image */}
        <div className="mb-3">
          <label htmlFor="eventImage" className="form-label">Event Image</label>
          <input
            type="file"
            className="form-control"
            id="eventImage"
            name="eventImage"
            onChange={handleChange}
            accept="image/*"
            required
            ref={imageInputRef} // Attach the ref to the input
          />
        </div>

        {/* Image Preview */}
        {imagePreview && (
          <div className="mb-3">
            {/* <label className="form-label">Image Preview</label> */}
            <img src={imagePreview} alt="Preview" className="img-fluid" style={{ maxHeight: '200px', objectFit: 'cover' }} />
          </div>
        )}

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">Add Event</button>
      </form>

      {/* Toast Container for notifications */}
      <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
    </div>
  );
};

export default AddEvents;
