import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Jobs = () => {
  const navigate = useNavigate();
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5;

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/jobs');
        setJobData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch job data');
        setLoading(false);
      }
    };
    fetchJobs();
  }, []);

  const handleDeleteClick = (job) => {
    setSelectedJob(job);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedJob) return;
    try {
      await axios.delete(`https://teachire.com/api/jobs/delete/${selectedJob._id}`);
      setJobData(jobData.filter((job) => job._id !== selectedJob._id));
      toast.success('Job deleted successfully!');
      setSelectedJob(null);
    } catch (error) {
      toast.error('Failed to delete job. Please try again.');
    }
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  // Pagination calculation
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobData.slice(indexOfFirstJob, indexOfLastJob);
  const totalPages = Math.ceil(jobData.length / jobsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };
  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  if (loading) return <p>Loading jobs...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mt-4">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Jobs</h2>
        <button className="btn btn-primary" onClick={() => navigate('/addjobs')}>Add Job</button>
      </div>
      <table className="table table-striped table-hover border shadow">
        <thead>
          <tr>
            <th>S. No</th>
            <th>Job Image</th>
            <th>Company Name</th>
            <th>Job Title</th>
            <th>Location</th>
            <th>Job Type</th>
            <th>Salary</th>
            <th>Posted Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentJobs.length > 0 ? (
            currentJobs.map((job, index) => (
              <tr key={job._id}>
                <td>{indexOfFirstJob + index + 1}</td>
                <td>
                  {job.jobImage ? (
                    <img
                      src={`https://teachire.com/uploads/jobs/${job.jobImage}`}
                      alt={job.jobTitle}
                      onError={(e) => { e.target.onerror = null; e.target.src = "path/to/fallback/image.png"; }}
                      style={{ width: '100px', height: 'auto' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{job.companyName}</td>
                <td>{job.jobTitle}</td>
                <td>{job.location}</td>
                <td>{job.jobType}</td>
                <td>{job.salary}</td>
                <td>{job.postedDate}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(job)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">No job listings available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div className="modal fade" id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Job</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this job?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-danger" onClick={handleDeleteConfirm}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
