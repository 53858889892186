import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Sangas = () => {
  const [sangas, setSangas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSanga, setSelectedSanga] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const sangasPerPage = 5;

  useEffect(() => {
    const fetchSangas = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/sangas');
        setSangas(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch sangas data');
        setLoading(false);
      }
    };

    fetchSangas();
  }, []);

  const handleDeleteClick = (sanga) => {
    setSelectedSanga(sanga);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedSanga) return;

    try {
      await axios.delete(`https://teachire.com/api/sangas/delete/${selectedSanga._id}`);
      setSangas(sangas.filter((s) => s._id !== selectedSanga._id));
      toast.success('Sanga deleted successfully!');
      setSelectedSanga(null);
    } catch (error) {
      console.error('Failed to delete sanga', error);
      toast.error('Failed to delete sanga. Please try again.');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  // Pagination logic
  const indexOfLastSanga = currentPage * sangasPerPage;
  const indexOfFirstSanga = indexOfLastSanga - sangasPerPage;
  const currentSangas = sangas.slice(indexOfFirstSanga, indexOfLastSanga);
  const totalPages = Math.ceil(sangas.length / sangasPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  if (loading) {
    return <p>Loading sangas...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Sangas</h2>
      </div>

      <table className="table table-striped table-hover border shadow">
        <thead className="thead-dark">
          <tr>
            <th>S. No</th>
            <th>Sanga Image</th>
            <th>Title</th>
            <th>Description</th>
            <th>Sanga Name</th>
            <th>Location</th>
            <th>Email</th>
            <th>Contact Number</th>
            <th>President Name</th>
            <th>Established Year</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentSangas.length > 0 ? (
            currentSangas.map((sanga, index) => (
              <tr key={sanga._id}>
                <td>{indexOfFirstSanga + index + 1}</td>
                <td>
                  {sanga.sangaImage ? (
                    <img
                      src={`https://teachire.com/uploads/sangas/${sanga.sangaImage}`}
                      alt={sanga.title}
                      onError={(e) => { e.target.onerror = null; e.target.src = "path/to/fallback/image.png"; }}
                      style={{ width: '100px', height: 'auto' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{sanga.title}</td>
                <td>{sanga.description}</td>
                <td>{sanga.sangaName}</td>
                <td>{sanga.location}</td>
                <td>{sanga.email}</td>
                <td>{sanga.contactNumber}</td>
                <td>{sanga.presidentName}</td>
                <td>{sanga.establishedYear}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(sanga)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center">No sangas available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Sanga</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this sanga?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sangas;
