import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Scholarships = () => {
  const navigate = useNavigate();
  const [scholarships, setScholarships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scholarshipToDelete, setScholarshipToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const scholarshipsPerPage = 5;

  useEffect(() => {
    const fetchScholarships = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/scholarships');
        const scholarshipsWithImages = response.data.map(scholarship => ({
          ...scholarship,
          scholarshipImage: scholarship.scholarshipImage
            ? `https://teachire.com/uploads/scholarships/${scholarship.scholarshipImage}`
            : null,
        }));
        setScholarships(scholarshipsWithImages);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch scholarship data');
        setLoading(false);
        toast.error('Failed to fetch scholarship data');
      }
    };

    fetchScholarships();
  }, []);

  const handleDelete = async () => {
    if (!scholarshipToDelete) return;

    try {
      await axios.delete(`https://teachire.com/api/scholarships/delete/${scholarshipToDelete}`);
      setScholarships(scholarships.filter(scholarship => scholarship._id !== scholarshipToDelete));
      setIsModalOpen(false);
      setScholarshipToDelete(null);
      toast.success('Scholarship deleted successfully!');
    } catch (err) {
      setError('Failed to delete scholarship');
      toast.error('Failed to delete scholarship');
    }
  };

  if (loading) {
    return <p>Loading scholarships...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const indexOfLastScholarship = currentPage * scholarshipsPerPage;
  const indexOfFirstScholarship = indexOfLastScholarship - scholarshipsPerPage;
  const currentScholarships = scholarships.slice(indexOfFirstScholarship, indexOfLastScholarship);
  const totalPages = Math.ceil(scholarships.length / scholarshipsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };
  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Scholarship</h5>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this scholarship?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Scholarships</h2>
        <button className="btn btn-primary" onClick={() => navigate('/addscholarships')}>Add Scholarship</button>
      </div>

      <table className="table table-striped table-hover border shadow rounded">
        <thead className="thead-dark">
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Image</th>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Eligibility</th>
            <th scope="col">Deadline</th>
            <th scope="col">Amount</th>
            <th scope="col">Selection Process</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile Number</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentScholarships.map((scholarship, index) => (
            <tr key={scholarship._id}>
              <td>{indexOfFirstScholarship + index + 1}</td>
              <td>
                {scholarship.scholarshipImage && (
                  <img src={scholarship.scholarshipImage} alt={scholarship.title} style={{ width: '100px', height: 'auto' }} />
                )}
              </td>
              <td>{scholarship.title}</td>
              <td>{scholarship.description}</td>
              <td>{scholarship.eligibility}</td>
              <td>{new Date(scholarship.deadline).toLocaleDateString()}</td>
              <td>${scholarship.amount}</td>
              <td>{scholarship.selectionProcess}</td>
              <td>{scholarship.email}</td>
              <td>{scholarship.mobileNumber}</td>
              <td>
                <button 
                  className="btn btn-danger" 
                  onClick={() => {
                    setScholarshipToDelete(scholarship._id);
                    setIsModalOpen(true);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onConfirm={handleDelete} 
      />

      <ToastContainer />
    </div>
  );
};

export default Scholarships;
