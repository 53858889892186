import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddScholarships = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [eligibility, setEligibility] = useState('');
  const [deadline, setDeadline] = useState('');
  const [amount, setAmount] = useState('');
  const [selectionProcess, setSelectionProcess] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [scholarshipImage, setScholarshipImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const imageInputRef = useRef();
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "scholarshipImage") {
      const file = e.target.files[0];
      setScholarshipImage(file);
      if (file) {
        const previewUrl = URL.createObjectURL(file);
        setImagePreview(previewUrl);
      } else {
        setImagePreview(null);
      }
    } else {
      const { id, value } = e.target;
      switch (id) {
        case 'title':
          setTitle(value);
          break;
        case 'description':
          setDescription(value);
          break;
        case 'eligibility':
          setEligibility(value);
          break;
        case 'deadline':
          setDeadline(value);
          break;
        case 'amount':
          setAmount(value);
          break;
        case 'selectionProcess':
          setSelectionProcess(value);
          break;
        case 'email':
          setEmail(value);
          break;
        case 'mobileNumber':
          setMobileNumber(value);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('eligibility', eligibility);
    formData.append('deadline', deadline);
    formData.append('amount', amount);
    formData.append('selectionProcess', selectionProcess);
    formData.append('email', email);
    formData.append('mobileNumber', mobileNumber);
    if (scholarshipImage) formData.append('scholarshipImage', scholarshipImage);

    try {
      const response = await axios.post('https://teachire.com/api/scholarships/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Scholarship added successfully:', response.data);
      toast.success('Scholarship added successfully!');
      // Reset form fields and preview
      setTitle('');
      setDescription('');
      setEligibility('');
      setDeadline('');
      setAmount('');
      setSelectionProcess('');
      setEmail('');
      setMobileNumber('');
      setScholarshipImage(null);
      setImagePreview(null);
      imageInputRef.current.value = ''; // Clear file input
      navigate('/scholarships'); // Redirect to scholarships page
    } catch (error) {
      console.error('Error adding scholarship:', error);
      toast.error('Failed to add scholarship. Please try again.');
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Add Scholarship</h2>
      <form onSubmit={handleSubmit} className="border p-5 rounded shadow">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            rows="4"
            value={description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="eligibility" className="form-label">Eligibility</label>
          <textarea
            className="form-control"
            id="eligibility"
            rows="2"
            value={eligibility}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="deadline" className="form-label">Deadline</label>
          <input
            type="date"
            className="form-control"
            id="deadline"
            value={deadline}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">Amount</label>
          <input
            type="number"
            className="form-control"
            id="amount"
            value={amount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="selectionProcess" className="form-label">Selection Process</label>
          <textarea
            className="form-control"
            id="selectionProcess"
            rows="2"
            value={selectionProcess}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Contact Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="mobileNumber" className="form-label">Contact Mobile Number</label>
          <input
            type="tel"
            className="form-control"
            id="mobileNumber"
            value={mobileNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="scholarshipImage" className="form-label">Scholarship Image</label>
          <input
            type="file"
            className="form-control"
            id="scholarshipImage"
            name="scholarshipImage"
            onChange={handleChange}
            accept="image/*"
            ref={imageInputRef}
          />
        </div>
        {imagePreview && (
          <div className="mb-3">
            <img src={imagePreview} alt="Preview" className="img-fluid" style={{ maxHeight: '200px', objectFit: 'cover' }} />
          </div>
        )}
        <button type="submit" className="btn btn-primary">Add Scholarship</button>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddScholarships;
