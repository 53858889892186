import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

// const BASE_URL = 'https://teachire.com'; // Base URL for your backend
const BASE_URL = 'https://teachire.com'; // Base URL for your backend

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showToast, setShowToast] = useState(false); // State for toast visibility
  const usersPerPage = 5;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch users', error);
      }
    };

    fetchUsers();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(users.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedUser) return;

    try {
      await axios.delete(`${BASE_URL}/api/users/${selectedUser._id}`);
      setUsers(users.filter((user) => user._id !== selectedUser._id));
      setShowToast(true); // Show toast on successful deletion
      setSelectedUser(null);
    } catch (error) {
      console.error('Failed to delete user', error);
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  return (
    <div className="container mt-4">
      <h3>User List</h3>
      <table className="table table-bordered table-striped shadow">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>First Name</th>
            <th>Surname</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Country</th>
            <th>Profile Image</th>
            <th>Gender</th>
            <th>Birthdate</th>
            <th>Profession</th>
            <th>Address</th>
            <th>Pincode</th>
            <th>Bio</th>
            <th>bloodGroup</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.length > 0 ? (
            currentUsers.map((user, index) => (
              <tr key={user._id}>
                <td>{indexOfFirstUser + index + 1}</td>
                <td>{user.firstName}</td>
                <td>{user.surname}</td>
                <td>{user.email}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.selectedCountry}</td>
                <td>
                  {user.profileImage ? (
                    <img
                      src={`${BASE_URL}/uploads/${user.profileImage}`} // Dynamic profile image URL
                      alt={`${user.firstName} ${user.surname}`}
                      style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{user.gender}</td>
                <td>{user.birthdate}</td>
                <td>{user.profession}</td>
                <td>{user.address}</td>
                <td>{user.pincode}</td>
                <td>{user.bio}</td>
                <td>{user.bloodGroup}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(user)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12" className="text-center">No users available</td>
            </tr>
          )}
        </tbody>
      </table>

      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete User</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this user?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Toast for successful deletion */}
      <div className={`toast align-items-center text-bg-success position-fixed top-0 end-0 m-3 ${showToast ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
          <div className="toast-body">
            User deleted successfully!
          </div>
          <button type="button" className="btn-close btn-close-white" onClick={() => setShowToast(false)} aria-label="Close"></button>
        </div>
      </div>
    </div>
  );
};

export default UserList;
