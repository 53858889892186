import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BASE_URL = 'http://192.168.1.29:5000'; // Base URL for your backend

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeesPerPage] = useState(5); // Number of employees per page
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/employees`); // Adjust API endpoint as necessary
        setEmployees(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch employees data');
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = employees.slice(indexOfFirstEmployee, indexOfLastEmployee);
  const totalPages = Math.ceil(employees.length / employeesPerPage);

  const handleDeleteClick = (employee) => {
    setSelectedEmployee(employee);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedEmployee) return;

    try {
      await axios.delete(`${BASE_URL}/api/employees/delete/${selectedEmployee._id}`);
      setEmployees(employees.filter((emp) => emp._id !== selectedEmployee._id));
      toast.success('Employee deleted successfully!');
      setSelectedEmployee(null);
    } catch (error) {
      console.error('Failed to delete employee', error);
      toast.error('Failed to delete employee. Please try again.');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  if (loading) {
    return <p>Loading employees...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Employees</h2>
      </div>

      <table className="table table-striped table-hover border shadow">
        <thead className="thead-dark">
          <tr>
            <th>S. No</th>
            <th>Employee Image</th>
            <th>Full Name</th>
            <th>Designation</th>
            <th>Department</th>
            <th>Mobile Number</th>
            <th>Gender</th>
            <th>Birth Date</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEmployees.length > 0 ? (
            currentEmployees.map((employee, index) => (
              <tr key={employee._id}>
                <td>{indexOfFirstEmployee + index + 1}</td>
                <td>
                  {employee.employeeImage ? (
                    <img
                      src={`${BASE_URL}/uploads/employees/${employee.employeeImage}`} // Adjust path as necessary
                      alt={employee.name}
                      onError={(e) => { e.target.onerror = null; e.target.src = "path/to/fallback/image.png"; }}
                      style={{ width: '100px', height: 'auto' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{employee.name}</td>
                <td>{employee.designation}</td>
                <td>{employee.department}</td>
                <td>{employee.mobileNumber}</td>
                <td>{employee.gender}</td>
                <td>{new Date(employee.birthDate).toLocaleDateString()}</td>
                <td>{employee.address}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(employee)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">No employees available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Employee</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this employee?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
