import React from 'react';

const Settings = () => {
  return (
    <div>
      <h3>Settings Page</h3>
    </div>
  );
};

export default Settings;
