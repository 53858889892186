import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Jobseekers = () => {
  const [jobseekers, setJobseekers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJobseeker, setSelectedJobseeker] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const jobseekersPerPage = 5;

  useEffect(() => {
    const fetchJobseekers = async () => {
      try {
        const response = await axios.get('https://teachire.com/api/jobseekers');
        setJobseekers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch jobseeker data');
        setLoading(false);
      }
    };

    fetchJobseekers();
  }, []);

  const handleDeleteClick = (jobseeker) => {
    setSelectedJobseeker(jobseeker);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedJobseeker) return;

    try {
      await axios.delete(`https://teachire.com/api/jobseekers/delete/${selectedJobseeker._id}`);
      setJobseekers(jobseekers.filter((jobseeker) => jobseeker._id !== selectedJobseeker._id));
      toast.success('Jobseeker deleted successfully!');
      setSelectedJobseeker(null);
    } catch (error) {
      console.error('Failed to delete jobseeker', error);
      toast.error('Failed to delete jobseeker. Please try again.');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  const indexOfLastJobseeker = currentPage * jobseekersPerPage;
  const indexOfFirstJobseeker = indexOfLastJobseeker - jobseekersPerPage;
  const currentJobseekers = jobseekers.slice(indexOfFirstJobseeker, indexOfLastJobseeker);
  const totalPages = Math.ceil(jobseekers.length / jobseekersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  if (loading) {
    return <p>Loading jobseekers...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container mt-4">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Jobseekers</h2>
      </div>

      <table className="table table-striped table-hover border shadow">
        <thead className="thead-dark">
          <tr>
            <th>S. No</th>
            <th>Jobseeker Image</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number</th>
            <th>Date of Birth</th>
            <th>Address</th>
            <th>Qualification</th>
            <th>salary </th>
            <th>Skills</th>
            <th>Level of Education</th>
            <th>College Name</th>
            <th>Academic Achievements</th>
            <th>Previous Job Title</th>
            <th>Previous Company</th>
            <th>Expected CTC</th>
            <th>Preferred Job Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentJobseekers.length > 0 ? (
            currentJobseekers.map((jobseeker, index) => (
              <tr key={jobseeker._id}>
                <td>{indexOfFirstJobseeker + index + 1}</td>
                <td>
                  {jobseeker.jobseekerImage ? (
                    <img
                      src={`https://teachire.com/uploads/jobseekers/${jobseeker.jobseekerImage}`}
                      alt={jobseeker.name}
                      onError={(e) => { e.target.onerror = null; e.target.src = "path/to/fallback/image.png"; }}
                      style={{ width: '100px', height: 'auto' }}
                    />
                  ) : (
                    <span>No Image</span>
                  )}
                </td>
                <td>{jobseeker.name}</td>
                <td>{jobseeker.email}</td>
                <td>{jobseeker.mobile}</td>
                <td>{new Date(jobseeker.dateOfBirth).toLocaleDateString()}</td>
                <td>{jobseeker.address}</td>
                <td>{jobseeker.qualification}</td>
                <td>{jobseeker.experience}</td>
                <td>{jobseeker.skills.join(', ')}</td>
                <td>{jobseeker.levelOfEducation}</td>
                <td>{jobseeker.collegeName || 'N/A'}</td>
                <td>{jobseeker.academicAchievements.join(', ') || 'N/A'}</td>
                <td>{jobseeker.previousJobTitle || 'N/A'}</td>
                <td>{jobseeker.previousCompanyName || 'N/A'}</td>
                <td>{jobseeker.expectedCTC || 'N/A'}</td>
                <td>{jobseeker.preferredJobType || 'N/A'}</td>
                <td>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(jobseeker)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="18" className="text-center">No jobseekers available</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Jobseeker</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this jobseeker?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobseekers;
