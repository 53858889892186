import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MatrimonyList = () => {
  const [matrimonyUsers, setMatrimonyUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const usersPerPage = 5;

  // Fetch matrimony users from the backend
  useEffect(() => {
    const fetchMatrimonyUsers = async () => {
      try {
        // const response = await axios.get('https://teachire.com/api/matrimony'); // Adjust URL to your backend
        const response = await axios.get('https://teachire.com/api/matrimony'); // Adjust URL to your backend
        setMatrimonyUsers(response.data);
      } catch (error) {
        console.error('Failed to fetch matrimony users', error);
      }
    };

    fetchMatrimonyUsers();
  }, []);

  // Calculate the displayed matrimony users based on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = matrimonyUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Total number of pages
  const totalPages = Math.ceil(matrimonyUsers.length / usersPerPage);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle "Previous" and "Next" buttons
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  // Handle delete button click
  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  // Handle delete confirmation
  const handleDeleteConfirm = async () => {
    if (!selectedUser) return;

    try {
      await axios.delete(`https://teachire.com/api/matrimony/delete/${selectedUser._id}`);
      // await axios.delete(`https://teachire.com/api/matrimony/delete/${selectedUser._id}`);
      setMatrimonyUsers(matrimonyUsers.filter((user) => user._id !== selectedUser._id));
      setSelectedUser(null);
      toast.success('User deleted successfully!');
    } catch (error) {
      console.error('Failed to delete user', error);
      toast.error('Failed to delete user');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  return (
    <div className="container mt-4">
      <h3>Matrimony User List</h3>
      <div className="table-responsive">
        <table className="table table-bordered table-striped shadow">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Date of Birth</th>
              <th>Profile Picture</th>
              <th>Gender</th>
              <th>Marital Status</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
              <th>Education</th>
              <th>Occupation</th>
              <th>Company</th>
              <th>Annual Income</th>
              <th>Work Location</th>
              <th>Father's Name</th>
              <th>Mother's Name</th>
              <th>Siblings</th>
              <th>Family Type</th>
              <th>Family Status</th>
              <th>Family Values</th>
              <th>Diet</th>
              <th>Smoking</th>
              <th>Drinking</th>
              <th>Hobbies</th>
              <th>Languages Known</th>
              <th>Height</th>
              <th>Location Preference</th>
              <th>Diet Preference</th>
              <th>Other Preferences</th>
              <th>Bio</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.length > 0 ? (
              currentUsers.map((user, index) => (
                <tr key={user._id}>
                  <td>{indexOfFirstUser + index + 1}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.dob}</td>
                  {/* <td><img src={user.profilePicture} alt="Profile" width="50" height="50" /></td> */}
                  <td><img src={`https://teachire.com/uploads/matrimonyUsers/${user.matrimonyImage}`} alt="Profile" width="50" height="50" /></td>
                  <td>{user.gender}</td>
                  <td>{user.maritalStatus}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.street}</td>
                  <td>{user.city}</td>
                  <td>{user.state}</td>
                  <td>{user.country}</td>
                  <td>{user.education}</td>
                  <td>{user.occupation}</td>
                  <td>{user.company}</td>
                  <td>{user.annualIncome}</td>
                  <td>{user.workLocation}</td>
                  <td>{user.fatherName}</td>
                  <td>{user.motherName}</td>
                  <td>{user.siblings}</td>
                  <td>{user.familyType}</td>
                  <td>{user.familyStatus}</td>
                  <td>{user.familyValues}</td>
                  <td>{user.diet}</td>
                  <td>{user.smoking}</td>
                  <td>{user.drinking}</td>
                  <td>{user.hobbies}</td>
                  <td>{user.languagesKnown}</td>
                  <td>{user.height}</td>
                  <td>{user.locationPreference}</td>
                  <td>{user.dietPreference}</td>
                  <td>{user.otherPreferences}</td>
                  <td>{user.bio}</td>
                  <td>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(user)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="38" className="text-center">No matrimony users available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete User</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this user?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Container for notifications */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick draggable pauseOnHover />
    </div>
  );
};

export default MatrimonyList;
