import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BASE_URL = 'https://teachire.com'; // Ensure this is the correct base URL
// const BASE_URL = 'https://teachire.com'; // Ensure this is the correct base URL

const BusinessList = () => {
  const [businesses, setBusinesses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const businessesPerPage = 5;

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/businesses`);
        setBusinesses(response.data);
        console.log('Fetched businesses:', response.data);
      } catch (error) {
        console.error('Failed to fetch businesses', error);
      }
    };

    fetchBusinesses();
  }, []);

  const indexOfLastBusiness = currentPage * businessesPerPage;
  const indexOfFirstBusiness = indexOfLastBusiness - businessesPerPage;
  const currentBusinesses = businesses.slice(indexOfFirstBusiness, indexOfLastBusiness);
  const totalPages = Math.ceil(businesses.length / businessesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevious = () => {
    if (currentPage > 1) paginate(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) paginate(currentPage + 1);
  };

  const handleDeleteClick = (business) => {
    setSelectedBusiness(business);
    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.show();
  };

  const handleDeleteConfirm = async () => {
    if (!selectedBusiness) return;

    try {
      await axios.delete(`${BASE_URL}/api/businesses/delete/${selectedBusiness._id}`);
      setBusinesses(businesses.filter((business) => business._id !== selectedBusiness._id));
      setSelectedBusiness(null);
      toast.success('Business deleted successfully!');
    } catch (error) {
      console.error('Failed to delete business', error);
      toast.error('Failed to delete business');
    }

    const deleteModal = new window.bootstrap.Modal(document.getElementById('deleteModal'));
    deleteModal.hide();
  };

  return (
    <div className="container mt-4">
      <h3>Business List</h3>
      <div className="table-responsive">
        <table className="table table-bordered table-striped shadow">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Business Name</th>
              <th>Industry Type</th>
              <th>Year Established</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Website URL</th>
              <th>Address</th>
              <th>Description</th>
              <th>Products/Services</th>
              <th>Business Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentBusinesses.length > 0 ? (
              currentBusinesses.map((business, index) => (
                <tr key={business._id}>
                  <td>{indexOfFirstBusiness + index + 1}</td>
                  <td>{business.businessName}</td>
                  <td>{business.industryType}</td>
                  <td>{business.yearEstablished}</td>
                  <td>{business.businessEmail}</td>
                  <td>{business.phoneNumber}</td>
                  <td>
                    <a href={business.websiteURL} target="_blank" rel="noopener noreferrer">
                      {business.websiteURL}
                    </a>
                  </td>
                  <td>{business.physicalAddress}</td>
                  <td>{business.description}</td>
                  <td>
                    {Array.isArray(business.productsServices) && business.productsServices.length > 0
                      ? business.productsServices.join(', ')
                      : 'No products/services available'}
                  </td>
                  <td>
                    {business.businessImage ? (
                      <img
                        src={`${BASE_URL}/uploads/businesses/${business.businessImage}`} // Adjust image URL
                        alt="Business"
                        style={{ width: '100px', height: 'auto' }}
                      />
                    ) : (
                      'No image available'
                    )}
                  </td>
                  <td>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(business)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">No businesses available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <nav className="d-flex justify-content-end">
        <ul className="pagination" style={{ gap: '10px' }}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a onClick={handlePrevious} className="page-link" href="#">
              Previous
            </a>
          </li>
          <li className="page-item active">
            <span className="page-link">{currentPage}</span>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a onClick={handleNext} className="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* Delete Confirmation Modal */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">Delete Business</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this business?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteConfirm}
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Container for notifications */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick draggable pauseOnHover />
    </div>
  );
};

export default BusinessList;
