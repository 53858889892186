import React from 'react';
import UserList from '../components/UserList';

const Users = () => {
  return (
    <div>
      <UserList />
    </div>
  );
};

export default Users;
