import React from 'react'
import BusinessList from '../components/BusinessList'

const Business = () => {
  return (
    <div>
      <BusinessList/>
    </div>
  )
}

export default Business